import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import classnames from 'classnames';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { noop } from '@xxxlgroup/hydra-utils/common';
import {
  Button,
  Content,
  FeedbackCard,
  Heading,
  Link as HydraLink,
} from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink/WebshopLink';
import useMessage from 'components/Message/useMessage';
import useRoutes from 'hooks/useRoutes';
import FeatureFlag from 'components/FeatureFlag';
import UserDataForm from 'modules/customer-authentication/components/LoginForm/components/UserDataForm';
import PasswordResetRequest from 'modules/customer-authentication/components/PasswordResetRequest';

import styles from 'modules/customer-authentication/components/LoginForm/LoginForm.scss';

const views = {
  login: 'login',
  passwordResetRequest: 'passwordResetRequest',
};

const getHeading = (headingSize, content) => {
  if (headingSize === 'large') {
    return (
      <Heading
        level={2}
        SEO
        content={content}
        className={styles.headingLarge}
        data-testid="loginHeadingLarge"
      />
    );
  }

  if (headingSize === 'small') {
    return (
      <Content
        content={content}
        className={classnames(styles.heading, styles.headingSmall)}
        data-testid="loginHeadingSmall"
        tag="p"
      />
    );
  }

  return null;
};

const LoginForm = (props) => {
  const {
    className,
    heading,
    noSignup,
    withInvalidTokenFeedback,
    withPasswordChangedFeedback,
    onLogin,
    onClickSignUp,
    onViewChanged,
  } = props;
  const { customerAuthentication } = useRoutes();
  const tracking = useTracking(props, 'LoginForm');
  const [
    somethingWentWrong,
    passwordReset,
    invalidTokenError,
    successNotification,
    titleLogin,
    titlePasswordResetRequest,
    signupTitle,
    signupButton,
    passwordChangedFeedback,
  ] = useMessage([
    'wxs.user.login.failure',
    'user.passwordReset.text',
    'user.login.invalid.token',
    'wxs.user.login.success',
    'wxs.user.login.title',
    'user.passwordReset.modal.title',
    'signin.signup.title',
    'signin.signup.button',
    'customerAuthentication.signIn.passwordChanged',
  ]);
  const [view, setView] = useState(views.login);
  const [loginResponse, setLoginResponse] = useState({ data: null, errors: null });
  const onLoginProxy = (data, errors) => {
    const trackingData = {
      success: !!data?.login,
      errors,
    };
    setLoginResponse({ data, errors });
    onLogin(data, errors);
    tracking('click', trackingData);
  };
  const getViewHeading = useCallback(() => {
    switch (view) {
      case views.passwordResetRequest:
        return titlePasswordResetRequest;
      case views.login:
      default:
        return titleLogin;
    }
  }, [view, titleLogin, titlePasswordResetRequest]);

  const setLoginView = () => {
    setView(views.login);
  };

  const setPasswordResetRequestView = (event) => {
    setView(views.passwordResetRequest);
    tracking(event);
  };

  const renderedPasswordReset = () => (
    <>
      {getHeading(heading, getViewHeading())}
      <PasswordResetRequest onToLoginFormClick={setLoginView} />
    </>
  );

  const renderLoginView = () => {
    const loginErrors = loginResponse.errors;
    const loginSuccessful = loginResponse.data?.login && !loginErrors;

    if (loginSuccessful) {
      return (
        <FeedbackCard variant="success" className={styles.successFeedbackCard}>
          {successNotification}
        </FeedbackCard>
      );
    }

    return (
      <>
        {getHeading(heading, getViewHeading())}
        {loginErrors && (
          <FeedbackCard variant="error" data-testid="login.error.feedbackcard">
            <Content content={somethingWentWrong} size="sm" tag="p" />
          </FeedbackCard>
        )}
        {withInvalidTokenFeedback && (
          <FeedbackCard variant="warning">{invalidTokenError}</FeedbackCard>
        )}
        {withPasswordChangedFeedback && (
          <FeedbackCard variant="success">{passwordChangedFeedback}</FeedbackCard>
        )}
        <UserDataForm onLogin={onLoginProxy} />
        <FeatureFlag code="poseidon.forgot.password.enabled">
          <HydraLink
            className={styles.passwordResetButton}
            onClick={setPasswordResetRequestView}
            theme="concrete"
            data-purpose="loginform.passwortReset.button"
            typography="small"
            underline
          >
            {passwordReset}
          </HydraLink>
        </FeatureFlag>
        {!noSignup && (
          <div className={styles.signup}>
            <Content content={signupTitle} className={styles.heading} tag="p" />
            <Link
              as={
                <Button layout="block" variant="secondary" data-testid="login.goto.signUp.button" />
              }
              href={`/${customerAuthentication.signUp.name}`}
              onClick={onClickSignUp}
            >
              {signupButton}
            </Link>
          </div>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (view) {
      case views.passwordResetRequest:
        return renderedPasswordReset();
      case views.login:
      default:
        return renderLoginView();
    }
  };

  useEffect(() => {
    onViewChanged({ heading: getViewHeading(views.login) });
  }, [view, onViewChanged, getViewHeading]);

  return (
    <div aria-live="polite" aria-atomic="true" aria-relevant="additions" className={className}>
      {renderContent()}
    </div>
  );
};

LoginForm.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** The size of the heading or null if no heading shall be rendered. */
  heading: PropTypes.oneOf(['large', 'small']),
  /** Whether to hide the signup link. */
  noSignup: PropTypes.bool,
  /** Whether to show feedback because of a invalid auth token. */
  withInvalidTokenFeedback: PropTypes.bool,
  /** Whether to show feedback for a successful password change. */
  withPasswordChangedFeedback: PropTypes.bool,
  /** Callback to be called when login process ends. */
  onLogin: PropTypes.func,
  /** Callback which gets called when clicking on the registration button. */
  onClickSignUp: PropTypes.func,
  /** Callback which gets called after the has changed and the component rendered. */
  onViewChanged: PropTypes.func,
};

LoginForm.defaultProps = {
  className: null,
  heading: null,
  noSignup: false,
  withInvalidTokenFeedback: false,
  withPasswordChangedFeedback: false,
  onLogin: noop,
  onClickSignUp: noop,
  onViewChanged: noop,
};

export default track(tagComponent('LoginForm'))(LoginForm);
