import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { Button } from '@xxxlgroup/hydra-ui-components';
import track from 'react-tracking';
import { validateEmail } from 'utils/validation';
import { trimSpaces } from 'utils/formatting';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import useMessage from 'components/Message/useMessage';
import { Field, Form } from 'components/Form';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import styles from 'modules/customer-authentication/components/LoginForm/components/UserDataForm/UserDataForm.scss';

const UserDataForm = (props) => {
  const { onLogin } = props;
  const { login } = useAuth();
  const submitButtonText = useMessage('wxs.user.login.submit.label');
  const [userIdPlaceholder, passwordPlaceholder, submitLabel, showPassword, hidePassword] =
    useMessage(
      [
        'wxs.user.login.userId.placeholder',
        'wxs.user.login.password.placeholder',
        'wxs.user.login.submit.label',
        'accessibility.user.login.password.arialabel.showPassword',
        'accessibility.user.login.password.arialabel.hidePassword',
      ],
      null,
      true,
    );
  const [loading, setLoading] = useState(false);
  const tracking = useTracking(props, 'UserDataForm');

  const handleSubmit = useCallback(
    async ({ userid, password }) => {
      setLoading(true);
      const { data, errors, exception } = await login(userid?.trim(), password);
      setLoading(false);
      onLogin(data, errors || exception);
    },
    [login, onLogin],
  );

  const handleClick = useCallback((event) => tracking(event), [tracking]);

  return (
    <>
      <Form className={styles.form} data-testid="login.form" onSubmit={handleSubmit}>
        <Field
          className={styles.inputField}
          type="email"
          placeholder={userIdPlaceholder}
          name="userid"
          autoComplete="email"
          inputMode="email"
          validators={[validateEmail()]}
          formatters={[trimSpaces]}
          isRequired
          requiredSymbol=""
          data-testid="login.userid"
        />
        <Field
          className={styles.inputField}
          data-testid="login.password"
          type="password"
          placeholder={passwordPlaceholder}
          name="password"
          autoComplete="current-password"
          isRequired
          requiredSymbol=""
          i18n={{ showPassword, hidePassword }}
        />
        <Button
          data-async
          data-testid="login.button"
          className={styles.submitButton}
          variant="primary"
          label={submitLabel}
          ariaLabel={submitLabel}
          loading={loading}
          layout="block"
          type="submit"
          onClick={handleClick}
          data-purpose="customerAuthentication.button.login"
        >
          {submitButtonText}
        </Button>
      </Form>
    </>
  );
};

UserDataForm.propTypes = {
  /* Callback to be called when login process ends. */
  onLogin: PropTypes.func,
};

UserDataForm.defaultProps = {
  onLogin: noop,
};

export default track(tagComponent('UserDataForm'))(UserDataForm);
