import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Button,
  Content,
  FeedbackCard,
  Link as HydraLink,
  List,
  ListItem,
} from '@xxxlgroup/hydra-ui-components';
import { arrowLeft } from '@xxxlgroup/hydra-icons';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { getErrorsFromGraphQLRequest } from 'utils/graphQL';
import { validateEmail } from 'utils/validation';
import useMessage from 'components/Message/useMessage';
import { Field, Form } from 'components/Form';

import { PASSWORD_RESET_INQUIRY_MUTATION } from 'modules/customer-authentication/components/PasswordResetRequest/PasswordResetRequest.mutation';
import styles from 'modules/customer-authentication/components/PasswordResetRequest/PasswordResetRequest.scss';

const PasswordResetRequest = ({ onToLoginFormClick }) => {
  const formApi = useRef(null);
  const [passwordResetErrors, setPasswordResetErrors] = useState([]);
  const [resetPasswordRequest, { data, error, loading }] = useMutation(
    PASSWORD_RESET_INQUIRY_MUTATION,
  );
  const [email, setEmail] = useState(null);

  const setFormApi = (formApiArg) => {
    formApi.current = formApiArg;
  };

  useEffect(() => {
    if (!loading && error) {
      const [validationErrors, requestErrors] = getErrorsFromGraphQLRequest(error);

      if (!isArrayEmpty(validationErrors)) {
        validationErrors.forEach((graphQLError) => {
          const { subject, message } = graphQLError;
          formApi && formApi.current.setError(subject, message);
        });
      }
      if (!isArrayEmpty(requestErrors)) {
        setPasswordResetErrors(requestErrors);
      }
    }
  }, [loading, error, formApi]);

  const [
    formDescription,
    submitButton,
    somethingWentWrong,
    successNotification,
    backToLogin,
    successDescriptionHeader,
    bulletPoint1,
    bulletPoint2,
    bulletPoint3,
    bulletPoint4,
    goToContactCenter,
  ] = useMessage(
    [
      'user.passwordResetRequest.formDescription',
      'user.passwordResetRequest.submit',
      'user.passwordResetRequest.feedbackCard.somethingWentWrong',
      'user.passwordReset.notification.success',
      'user.passwordReset.backToLogin',
      'user.passwordReset.successDescriptionHeader',
      'user.passwordReset.bulletPoint1',
      'user.passwordReset.bulletPoint2',
      'user.passwordReset.bulletPoint3',
      'user.passwordReset.bulletPoint4',
      'user.passwordReset.goToContactCenter',
    ],
    { email },
  );

  const handleSubmit = useCallback(
    (formData) => {
      setEmail(formData.email);
      resetPasswordRequest({
        fetchPolicy: 'no-cache',
        variables: { email: formData.email },
      });
    },
    [resetPasswordRequest],
  );

  const renderBackToLoginFormButton = () => (
    <HydraLink
      className={styles.backToLoginButton}
      glyphBefore={arrowLeft}
      onClick={onToLoginFormClick}
      theme="coal"
      typography="xxsmall"
    >
      {backToLogin}
    </HydraLink>
  );

  const renderError = () => {
    const hasErrors = !isArrayEmpty(passwordResetErrors);
    if (hasErrors) {
      return <FeedbackCard variant="error">{somethingWentWrong}</FeedbackCard>;
    }

    return null;
  };

  return (
    <div>
      {!data?.resetPasswordInquiry && (
        <>
          <Content
            content={formDescription}
            id="passwordReset"
            size="sm"
            className={styles.formDescription}
            tag="p"
          />
          <Form onSubmit={handleSubmit} getApi={setFormApi}>
            <Field
              autoComplete="email"
              className={styles.emailField}
              labelCode="wxs.form.input.email.label"
              placeholderCode="wxs.form.input.email.placeholder"
              type="email"
              name="email"
              validators={[validateEmail()]}
              inputMode="email"
              isRequired
              requiredSymbol=""
              aria-describedby="passwordReset"
            />
            <Button className={styles.submitButton} type="submit" layout="block" loading={loading}>
              {submitButton}
            </Button>
          </Form>
          {renderError()}
        </>
      )}
      {!loading && data?.resetPasswordInquiry && (
        <>
          <FeedbackCard variant="success" className={styles.successFeedbackCard}>
            <Content content={successNotification} size="sm" />
          </FeedbackCard>
          <Content
            className={styles.successDescriptionHeader}
            content={successDescriptionHeader}
            size="sm"
          />
          <List>
            {[bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4].map((bulletPoint) => (
              <ListItem key={bulletPoint}>
                <Content content={bulletPoint} size="sm" />
              </ListItem>
            ))}
          </List>
          <Content content={goToContactCenter} size="sm" />
        </>
      )}
      {onToLoginFormClick && renderBackToLoginFormButton()}
    </div>
  );
};

PasswordResetRequest.propTypes = {
  /** Callback for changing the content of the modal */
  onToLoginFormClick: PropTypes.func,
};

PasswordResetRequest.defaultProps = {
  onToLoginFormClick: null,
};

export default PasswordResetRequest;
